import {LitElement, html} from 'lit';
import {customElement} from 'lit/decorators';
import {until} from 'lit/directives/until';
import '@iconify/iconify';
import {Departure, HvvDepaturesResponse, Time} from '../apis/HvvDepartures';
import {DateTime} from 'luxon';

@customElement('hvv-depatures')
export class HvvDepaturesElement extends LitElement {
  render() {
    return html` ${until(this.getDepartures(), html`loading`)} `;
  }

  createRenderRoot() {
    return this;
  }

  async getDepartures() {
    const res: HvvDepaturesResponse = await fetch(
      'https://www.hvv.de/geofox/departureList',
      {
        headers: {
          accept: 'application/json',
          'accept-language': 'en-US,en;q=0.9,de-DE;q=0.8,de;q=0.7',
          'content-type': 'application/json',
          'sec-fetch-dest': 'empty',
          'sec-fetch-mode': 'cors',
          'sec-fetch-site': 'same-origin',
        },
        body: JSON.stringify({
          version: 47,
          stations: [
            {
              name: 'Wandsbek Markt',
              id: 'Master:60902',
              city: 'Hamburg',
              type: 'STATION',
            },
            {
              name: 'Wendemuthstraße',
              id: 'Master:60008',
              city: 'Hamburg',
              type: 'STATION',
            },
            {
              name: 'Wandsbeker Chaussee',
              id: 'Master:60951',
              city: 'Hamburg',
              type: 'STATION',
            }
          ],
          filter: [
            {serviceID: 'HHA-U:U1_HHA-U', stationIDs: ['Master:60901']},
            {serviceID: 'HHA-U:U1_HHA-U', stationIDs: ['Master:60951']},
            {serviceID: 'HHA-B:23_HHA-B', stationIDs: ['Master:60002']},
            {serviceID: 'HHA-B:213_HHA-B', stationIDs: ['Master:60002']},
            {serviceID: 'HHA-B:X22_HHA-B', stationIDs: ['Master:60010']},
            { serviceID: 'HHA-B:9_HHA-B', stationIDs: [] },
            { serviceID: 'ZVU-DB:S1_ZVU-DB_SBHZVU', stationIDs: ['Master:60950'] },
            { serviceID: 'ZVU-DB:S1_ZVU-DB_SBHZVU', stationIDs: ['Master:60952'] },
          ],
          time: {
            date: DateTime.now().toFormat('dd.MM.yyyy'),
            time: DateTime.now().plus({minutes: 1}).toFormat('HH.mm'),
          },
          maxList: 50,
          allStationsInChangingNode: true,
          maxTimeOffset: 200,
          useRealtime: true,
        }),
        method: 'POST',
        mode: 'cors',
      }
    ).then((res) => res.json());

    const linesAndTimes: Map<string, Departure[]> = new Map();

    res.departures
      .filter((a) => a.cancelled !== false)
      .filter(a => a.line.direction !== "U Wandsbek Markt")
      .filter((a) => a.line.name !== "9" || a.station.combinedName === "Wendemuthstraße")
      .filter((a) => a.line.name !== "U1" || a.station.combinedName === "Wandsbek Markt")
      .forEach(dep => {
        const lineKey = `${dep.line.name}-${dep.directionId}`
        linesAndTimes.set(
          lineKey,
          [... (linesAndTimes.get(lineKey) ?? []), dep]
        )
      })

    function renderLineTimes(lineKey: string) {
      return html`
        <div class="flex gap-2">
          ${linesAndTimes
            .get(lineKey)
            ?.map(dep => html`<span class="${dep.delay > 0 ? 'text-red' : ''}">${calcTime(res.time, dep.timeOffset, dep.delay) }</span>`)
            .slice(0, 3)
          }
        </div>`
    }

    function renderLine(lineKey: string, name: string, direction: string, lineColor: 'bg-red' | 'bg-black') {
      return html`
        <div class="text-center">
          <div class="rounded-3xl px-2 text-white ${lineColor}">
            ${name}
          </div>
        </div>
        <div>
          ${direction}
        </div>
        ${renderLineTimes(lineKey)}
      `
    }

    return html`
      <div class="grid gap-4 font-bold text-xl" style="grid-template-columns: max-content max-content auto;">
        ${renderLine('U1-6', 'U1', 'HBF', 'bg-red')}
        ${renderLine('U1-1', 'U1', 'Norderstedt', 'bg-red')}
        ${renderLine('X22-6', 'X22', 'Hagenbeck', 'bg-black')}
        ${renderLine('23-1', '23', 'Billstedt', 'bg-black')}
        ${renderLine('213-1', '213', 'Billstedt', 'bg-black')}
        ${renderLine('9-1', '9', 'Rahlstedt', 'bg-black')}
        ${renderLine('S1-1', 'S1', 'HBF', 'bg-black')}
        ${renderLine('S1-6', 'S1', 'Airport', 'bg-black')}
      </div>
    `

    /*

    const depatures = res.departures
      .filter((a) => a.cancelled !== false)
      .filter(a => a.line.direction !== "U Wandsbek Markt")
      .filter((a) => a.line.name !== "9" || a.station.combinedName === "Wendemuthstraße")
      .sort((a, b) => (a.timeOffset + a.delay / 60) - (b.timeOffset + b.delay / 60));
    
    console.log(depatures)

    return html`<div class="grid gap-4 font-bold text-xl" style="grid-template-columns: max-content max-content auto;">
      ${[... interleafWithLines(depatures.map((depature) => renderDepature(res, depature)))]}
    </div>`;*/
  }
}

function* interleafWithLines(elements: unknown[]) {
  for (let i = 0; i < elements.length && i < 12; i++) {
    if ((i) % 4 === 0 && i !== 0) {
      yield html`<div class="col-span-3 border-2 border-black"></div>`
    }

    yield elements[i];
  }
}

function reduceStationName(name: string, maxLength: number) {
  if (name.length <= maxLength) return name

  const nameParts = name.split(" ")
  let reducedName = nameParts.shift()
  while (reducedName.length + nameParts[0].length + 1 < maxLength && nameParts.length > 0) {
    reducedName = reducedName + " " + nameParts.shift()
  }
  
  return reducedName
}

function renderDepature(res: HvvDepaturesResponse, depature: Departure) {
  return html`
      <div class="text-right font-mono">
        ${calcTime(res.time, depature.timeOffset, depature.delay)}
      </div>
      <div class="text-center">
        <div class="rounded-3xl px-2 text-white ${depature.line.name === 'U1' ? 'bg-red' : 'bg-black'}">
          ${depature.line.name}
        </div>
      </div>
      <div class="">${reduceStationName(depature.line.direction ,"U Hagenbecks Tierpark".length)}</div>
  `;
}

function calcTime(time: Time, offset: number, delayInS: number): string {
  return DateTime.fromFormat(time.time, 'HH:mm')
    .plus({minutes: offset})
    .plus({seconds: delayInS})
    .toFormat('HH:mm');
}

declare global {
  interface HTMLElementTagNameMap {
    'hvv-depatures': HvvDepaturesElement;
  }
}
